export const COLORS = {
  'brand-main-default-color': {
    light: 'hsl(0, 0%, 98%)',
    dark: 'hsl(252, 7%, 14%)',
  },
  'brand-main-default-color-hsl': {
    light: '0, 0%, 98%',
    dark: '252, 7%, 14%',
  },
  'brand-main-secondary-color': {
    light: 'hsl(0, 0%, 100%)',
    dark: 'hsl(255, 9%, 9%)',
  },
  'brand-main-secondary-color-hsl': {
    light: '0, 0%, 100%',
    dark: '255, 9%, 9%',
  },
  'brand-main-secondary-color-2': {
    dark: '#2a292f',
    light: '#e4e4e4',
  },
  'brand-main-accent-color': {
    light: '#ec512e',
    dark: '#ec512e',
  },
  'text-color-default': {
    light: '#333333',
    dark: '#e8e8e8',
  },
  'text-color-contrast': {
    light: '#000000',
    dark: '#FFFFFF',
  },
  'text-color-secondary': {
    light: '#333333',
    dark: '#cacaca',
  },
  'text-color-on-accent': {
    light: '#e8e8e8',
    dark: '#e8e8e8',
  },
  'bg-color-input': {
    light: '#e8e8e8',
    dark: '#e8e8e8',
  },
  'text-color-input': {
    light: '#161519',
    dark: '#161519',
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';
